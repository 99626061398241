import "./bootstrap";
import "./embla.js";

import { Alpine, Livewire } from "../../vendor/livewire/livewire/dist/livewire.esm";
import Clipboard from "@ryangjchandler/alpine-clipboard";

import Tooltip from "@ryangjchandler/alpine-tooltip";

import ui from "@alpinejs/ui";

import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

import * as FilePond from "filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

import SignaturePad from "signature_pad";

import "livewire-sortable";

import { Html5Qrcode } from "html5-qrcode";

import { Boarding } from "boarding.js";
import "boarding.js/styles/main.css";
// optionally include the base theme
import "boarding.js/styles/themes/basic.css";


flatpickr.localize(German);

window.setupEditor = function(content) {
  let editor;

  return {
    content: content,

    init(element) {
      editor = new Editor({
        element: element,
        extensions: [
          StarterKit,
          Link.configure({
            openOnClick: false,
            autolink: true,
            defaultProtocol: "https"
          })
        ],
        editorProps: {
          attributes: {
            class: "border prose max-w-xl rounded-lg p-4 shadow border-zinc-200 border-b-zinc-300/80"
          }
        },
        content: this.content,
        onUpdate: ({ editor }) => {
          this.content = editor.getHTML();
        }
      });
      this.$watch("content", (content) => {
        // If the new content matches TipTap's then we just skip.
        if (content === editor.getHTML()) return;
        editor.commands.setContent(content, false);
      });
    },
    toggleBold() {
      editor.chain().toggleBold().focus().run();
    },
    toggleItalic() {
      editor.chain().toggleItalic().focus().run();
    },
    toggleStrike() {
      editor.chain().focus().toggleStrike().run();
    },
    toggleBulletList() {
      editor.chain().focus().toggleBulletList().run();
    },
    toggleOrderedList() {
      editor.chain().focus().toggleOrderedList().run();
    },
    toggleLink() {
      const previousUrl = editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      // update link
      editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    },
    async insertHtml() {
      const clipboardContents = await navigator.clipboard.read();
      console.log(clipboardContents);
      editor.chain().focus().clearContent().run();

      for (const item of clipboardContents) {
        if (item.types.includes("text/html")) {
          const blob = await item.getType("text/html");
          const blobText = await blob.text();
          console.log(blobText);
          editor.chain().focus().insertContent(blobText).run();
        }
        if (item.types.includes("text/plain")) {
          const blob = await item.getType("text/plain");
          const blobText = await blob.text();
          console.log(blobText);
          editor.chain().focus().insertContent(blobText).run();
        }
      }
    }
  };
};

window.FilePond = FilePond;
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
window.FilePondPluginFileValidateSize = FilePondPluginFileValidateSize;
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

window.SignaturePad = SignaturePad;

window.Html5Qrcode = Html5Qrcode;

window.flatpickr = flatpickr;
window.Boarding = Boarding;


Alpine.plugin(Clipboard);
Alpine.plugin(Tooltip);
Alpine.plugin(ui);
Livewire.start();
